import React, { useEffect, useState, useMemo } from 'react';
import Moment from 'moment';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ClickAwayListener, Grow, Link, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { Switch } from '@mui/material';

import { getUsersByType } from '../../app/store/usersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import User, { Company, CompanySubmissionData, Contact } from '../../interfaces/users.interface';
import { updateCompany } from '../../services/company';
import { USER_TYPES, resendUserInvite, resetUser, updateUser } from '../../services/user';
import { DataCell, DataListContain, DataRow } from '../Tables/DataList';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import UserForm from './UserForm';
import canAdminCompany from '../../services/adminUtils';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NewUserForm from './NewUserForm';

export const UserDataRow = (props: { company: Company | undefined, user: User, index: number, handleUserDelete: any, includeAdminActionContent: boolean, userCanAdminCompany: boolean, isTpnAdmin: boolean, editUsersCallback: any }) => {
  let {
    userCanAdminCompany,
    company,
  } = props;
  const [user, setUser] = useState(props.user);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const handleDeleteUserOpen = () => setDeleteUserOpen(true);
  const handleDeleteUserClose = () => setDeleteUserOpen(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const handleEditUserOpen = () => setEditUserOpen(true);
  const handleEditUserClose = () => setEditUserOpen(false);
  const handleUserDeleteSubmit = async () => {
    if (!company) return;

    const employees = company.employees.map(e => e.id).filter(e => e !== user.id);
    const admins = company.admins.map(e => e.id).filter(e => e !== user.id);
    const pending = company.pendingEmployees.map(e => e.id).filter(e => e !== user.id);

    const updatedCompanyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: company.primaryContactUser?.id,
      employees: employees,
      pendingEmployees: pending,
      services: company.services.map((service) => service.id),
      locations: [...company.locations.map((contactLocation: Contact) => contactLocation.id)],
      admins: admins,
      subsidiaries: company.subsidiaries.map(item => item.id),
    };
    try {
      await updateCompany(updatedCompanyData);
      props.handleUserDelete(user);
      // Toggle is active status
      const newUser = { ...user, isCognitoUserActive: false };
      await updateUser(newUser);
      setUser(newUser);
    } catch (err) {
      console.error(err, ' Error removing user from company');
    }

    handleDeleteUserClose();
  };

  const handleEditUserSubmit = (updatedUser: User) => {
    setUser(updatedUser);
    handleEditUserClose();
  };
  const [canManageCompany, setCanManageCompany] = useState( company != undefined ? canAdminCompany(user, company) : false );

  const updateCompanyAdmins = (adminUser: User, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!company) return;
    const checked: boolean = event.target.checked;
    let admins: (number)[];
    if (checked) {
      admins = [...company.admins.map(item => item.id), adminUser.id];
    } else {
      admins = company.admins.filter(item => item.id != adminUser.id).map(item => item.id);
    }
    setCanManageCompany(checked);
    const updatedCompanyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: company.primaryContactUser?.id,
      employees: company.employees.map((employee) => employee.id),
      pendingEmployees: company.pendingEmployees.map((employee: User) => employee.id),
      services: company.services.map((service) => service.id),
      locations: [...company.locations.map((contactLocation: Contact) => contactLocation.id)],
      admins: admins,
      subsidiaries: company.subsidiaries.map(item => item.id),
    };
    updateCompany(updatedCompanyData);
  };

  const updateUserIsConsultant = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const newUser = { ...user, isConsultant: checked } as any;
    setUser(newUser);

    if (typeof newUser.contact === 'number') {
      delete newUser.contact;
    }
    
    await updateUser(newUser);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <DataRow onClick={() => {}} hover>
      <StandardDialog title={`Remove ${user.email}?`} handleClose={handleDeleteUserClose} isOpen={deleteUserOpen}>
        <Typography>Are you sure you want remove {user.email}?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleDeleteUserClose()}>Cancel</Button>
          <Button variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => handleUserDeleteSubmit()} disabled={!userCanAdminCompany}>Remove</Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog title={`Edit ${user.email}?`} handleClose={handleEditUserClose} isOpen={editUserOpen}>
        <Stack direction="row" spacing={2}>
          <UserForm successCallback={handleEditUserSubmit} userType={user.type} user={user} />
        </Stack>
      </StandardDialog>
      <DataCell xs={3}>
        <Link href={`mailto:${user.email}`} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={user.email}>{user.email}</Link>
      </DataCell>
      <DataCell xs={2}>{user.firstName}</DataCell>
      <DataCell xs={2}>{user.lastName}</DataCell>
      <DataCell xs={2}>{user.lastLogin ? Moment(user.lastLogin).format('MM/DD/YYYY h:mm:ss a') : 'N/A'}</DataCell>
      <DataCell xs={1}>{props.includeAdminActionContent && <Switch onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateCompanyAdmins(user, event)} checked={canManageCompany} disabled={!userCanAdminCompany} />}</DataCell>
      <DataCell xs={1}>{props.includeAdminActionContent && <Switch onChange={updateUserIsConsultant} checked={user.isConsultant} disabled={!userCanAdminCompany} />}</DataCell>
      <DataCell xs={1}>
        {userCanAdminCompany ?
          <>
          <IconButton
            name='Manage User'
            ref={anchorRef}
            id={`manage-user-${user.id}`}
            aria-controls={open ? `manage-user-${user.id}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="top-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'top-start' ? 'right top' : 'right bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id={`manage-user-menu-${user.id}`}
                      aria-labelledby={`manage-user-menu-${user.id}`}
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={(e) => {handleClose(e); handleEditUserOpen();}}>
                        <ListItemIcon><EditIcon /></ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={(e) => {handleClose(e); handleDeleteUserOpen();}}>
                        <ListItemIcon><DeleteIcon /></ListItemIcon>
                        <ListItemText>Remove</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={(e) => {handleClose(e); resendUserInvite(user);}}>
                        <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
                        <ListItemText>Resend Invite</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={(e) => {handleClose(e); resetUser(user);}}>
                        <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
                        <ListItemText>Reset</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
          :
          '-'
        }
      </DataCell>
    </DataRow>
  );
};

export const UsersContainer = (props: { users: User[] }) => {
  const { users: propUsers } = props;
  const {
    user: adminUser,
  } = useAppSelector(state => state.user);
  const [users, setUsers] = useState<Array<User>>(props.users);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const handleCreateUserOpen = () => setCreateUserOpen(true);
  const handleCreateUserClose = () => setCreateUserOpen(false);

  useEffect(() => {
    setUsers(propUsers);
  }, [props.users]);

  const addUserSuccessCallback = (user: User) => {
    setUsers([...users, user]);
    handleCreateUserClose();
  };

  const handleUserDeleteCallback = (user: User) => setUsers(users.filter((filteredUser: User) => filteredUser.id != user.id));
  const isTpnAdmin = useMemo(() => adminUser ? adminUser.type == USER_TYPES.TPN_ADMIN : false, [adminUser]);
  return (
    <DataListContain title="" buttonTitle="Admins" buttonClick={handleCreateUserOpen} searchList={props.users} onChange={() => {}} searchKey="email">
      <StandardDialog title="Invite TPN Admin" handleClose={handleCreateUserClose} isOpen={createUserOpen}>
        <NewUserForm successCallback={addUserSuccessCallback} modalClose={handleCreateUserClose} userType={USER_TYPES.TPN_ADMIN} />
      </StandardDialog>
      <DataRow onClick={() => {}} header>
        <DataCell xs={3}>Email</DataCell>
        <DataCell xs={2}>First Name</DataCell>
        <DataCell xs={3}>Last Name</DataCell>
        <DataCell xs={2}>Last Login</DataCell>
        <DataCell xs={2}>&nbsp;</DataCell>
      </DataRow>
      {users && users.map((user: User, index: number) => <UserDataRow key={user.id} company={undefined} user={user} index={index} handleUserDelete={handleUserDeleteCallback} editUsersCallback={setUsers} includeAdminActionContent={false} isTpnAdmin={isTpnAdmin} userCanAdminCompany={adminUser ? adminUser.type == USER_TYPES.TPN_ADMIN : false}/>)}
    </DataListContain>
  );
};


export default function Users(props: { userType: string }) {
  const dispatch = useAppDispatch();
  const { results: users } = useAppSelector(state => state.users);

  useEffect(() => {
    dispatch(getUsersByType(props.userType));
  }, []);

  return (
    <UsersContainer users={users} />
  );
}
