import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, TextField, Button } from '@mui/material';
import { isImageFile, isPDFFile, isDocxFile, isExcelFile } from '../../utils/fileUtils';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

import ImageViewer from './Views/ImageViewer';
import PdfViewer from './Views/PdfViewer';
import ExcelViewer from './Views/ExcelViewer';
import DocxViewer from './Views/DocxViewer';

interface FileViewerProps {
  preventDownload?: boolean;
  previewFile: string;
  closeFileViewer: () => void;
  showFileViewer: boolean;
}

const FileViewer: React.FC<FileViewerProps> = ({
  preventDownload,
  previewFile,
  closeFileViewer,
  showFileViewer,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageInput, setPageInput] = useState('1');
  const [zoom, setZoom] = useState(1);

  const handleCloseFileViewer = () => {
    closeFileViewer();
    setCurrentPage(0);
    setTotalPages(0);
    setPageInput('1');
    setZoom(1);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(previewFile);
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
      }

      const blob = await response.blob();
      const urlObject = new URL(previewFile);

      // Clean file name from the URL
      const cleanFileName = decodeURIComponent(urlObject.pathname.split('/').pop() || 'file.pdf');

      // Create, click, and remove link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', cleanFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading:', error);
      alert('Failed to download the file.');
    }
  };

  const handleSetTotalPages = (numPages: number) => {
    setTotalPages(numPages);
  };

  const handleDocumentLoadSuccess = (e: any) => {
    setTotalPages(e.doc.numPages);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(pageInput, 10);
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
        const newPageIndex = pageNumber - 1;
        setCurrentPage(newPageIndex);
        setPageInput((newPageIndex + 1).toString());
      } else {
        alert(`Please enter a valid page number between 1 and ${totalPages}`);
      }
    }
  };

  const handleNextPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (currentPage < totalPages - 1) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setPageInput((nextPage + 1).toString());
    }
  };

  const handlePreviousPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (currentPage > 0) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      setPageInput((prevPage + 1).toString());
    }
  };

  const handleZoomIn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newZoom = zoom + 0.1;
    setZoom(newZoom);
  };

  const handleZoomOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newZoom = Math.max(zoom - 0.1, 0.1);
    setZoom(newZoom);
  };

  // Check if the content is in landscape orientation (image or Excel file)
  const isLandscapeOrientation = isImageFile(previewFile) || isExcelFile(previewFile);
  const modalStyles = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isLandscapeOrientation ? '80%' : '75%',
    maxWidth: isLandscapeOrientation ? '1200px' : '1000px',
    height: isLandscapeOrientation ? 'auto' : '90%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    overflow: 'hidden',
  };

  return (
    <Modal open={showFileViewer} onClose={handleCloseFileViewer}>
      <Box sx={modalStyles} onClick={(e) => { e.preventDefault(); e.stopPropagation();}}>
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 8px 8px 8px',
            gap: 1,
          }}
        >
          {!preventDownload && (
            <IconButton
              sx={{ color: 'text.primary' }}
              onClick={handleDownload}
              aria-label="Download file"
            >
              <DownloadIcon />
            </IconButton>
          )}
          <IconButton
            sx={{ color: 'text.primary' }}
            onClick={handleCloseFileViewer}
            aria-label="Close preview"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <>
          {isImageFile(previewFile) ? (
            <ImageViewer previewFile={previewFile} zoom={zoom} />
          ) : isPDFFile(previewFile) ? (
            <PdfViewer
              previewFile={previewFile}
              handleDocumentLoadSuccess={handleDocumentLoadSuccess}
              zoom={zoom}
              currentPage={currentPage}
            />
          ) : isDocxFile(previewFile) ? (
            <DocxViewer
              handleSetTotalPages={handleSetTotalPages}
              previewFile={previewFile}
              currentPage={currentPage}
            />
          ) : isExcelFile(previewFile) ? (
            <ExcelViewer previewFile={previewFile} />
          ) : null}
        </>
        <Box
          sx={{
            position: 'fixed',
            bottom: 8,
            right: 16,
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {(isPDFFile(previewFile) || isImageFile(previewFile)) && (
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                gap: 1,
              }}
            >
              <IconButton onClick={handleZoomOut} disabled={zoom <= 0.5} sx={{ color: 'text.primary' }}>
                <RemoveIcon />
              </IconButton>
              <Typography
                variant="body1"
                display="inline"
                sx={{ textAlign: 'center', color: 'text.primary' }}
              >
                {(zoom * 100).toFixed(0)}%
              </Typography>
              <IconButton onClick={handleZoomIn} sx={{ color: 'text.primary' }}>
                <AddIcon />
              </IconButton>
            </Box>
          )}

          {totalPages > 1 && (
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                gap: 1,
                marginTop: '8px',
              }}
            >
              <Button onClick={handlePreviousPage} disabled={currentPage <= 0}>
                Previous
              </Button>
              <TextField
                value={pageInput}
                onChange={handlePageInputChange}
                onKeyDown={handlePageInputSubmit}
                type="number"
                size="small"
                inputProps={{ 
                  min: 1, 
                  max: totalPages, 
                  style: { textAlign: 'center',  color: 'text.primary' },
                }}
                sx={{
                  width: '55px',
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #ccc',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #ccc',
                    },
                  },
                }}
              />
              <Typography variant="body1" sx={{ color: 'text.primary' }}>/</Typography>
              <Typography variant="body1" sx={{ color: 'text.primary' }}>{totalPages}</Typography>
              <Button onClick={handleNextPage} disabled={currentPage >= totalPages - 1}>
                Next
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default FileViewer;