// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
// MUI
import { useTheme } from '@mui/material';
import { Button, IconButton, Stack, Typography, FormControlLabel, FormLabel, FormGroup, Switch } from '@mui/material';
import { Delete, Edit, NotificationImportantRounded } from '@mui/icons-material';
// redux
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateWorkflow } from '../../../app/store/workflowSlice';
import { deleteAssessment, updateAssessment } from '../../../app/store/assessmentSlice';
// interface
import { SiteCloudStatus, Survey } from '../../../interfaces/survey.interface';
import { Workflow } from '../../../interfaces/workflow.interface';
import User, { Company } from '../../../interfaces/users.interface';
import { Assessment, AssessmentSurvey } from '../../../interfaces/assessment.interface';
// services
import {
  assessmentRejectedAlert,
  fetchAssessment,
  updateMultipleAssessmentSurveys,
  updateAssessmentSurvey,
  getSiteCloudStatus,
} from '../../../services/assessments';

// components
import WorkflowStatus from '../WorkflowStatus';
import AssessmentSurveyRow from './AssessmentSurveyRow';
import AssessmentForm from '../../Assessments/NewAssessmentModal';
import AssessorAssessmentComments from '../../Comments/AssessorAssessmentComments';
import { Accordion, AccordionSummary } from '../../Layout/Collapsible';
import { DataCell, DataRow, DataRowActions } from '../../Tables/DataList';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { USER_TYPES } from '../../../services/user';
import { COMPANY_TYPES } from '../../../services/vendor';

type AssessmentDataRowProps = {
  company: Company;
  workflow: Workflow;
  vendorCompany?: Company;
  userCanAdminCompany: boolean;
};

const AssessmentDataRow = (props: AssessmentDataRowProps) => {
  const theme = useTheme();
  const { userCanAdminCompany, company, workflow, vendorCompany } = props;
  // redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { assessmentAuditLog } = useAppSelector((state) => state.assessments);
  const { workflows } = useAppSelector((state) => state.workflow);
  // states
  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [cAssessment, setcAssessment] = useState<Assessment | undefined>(undefined);
  const [surveys, setFilteredSurveys] = useState<Survey[]>([]);
  const [assessedCompany, setAssessedCompany] = useState<Company | undefined>(undefined);
  // modal states
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [deleteAssessmentOpen, setDeleteAssessmentOpen] = useState(false);
  const [acceptAssessmentOpen, setAcceptAssessmentOpen] = useState(false);
  const [rejectAssessmentOpen, setRejectAssessmentOpen] = useState(false);
  // local variables
  const activeWorkflows = useMemo(
    () => workflows?.filter((wf) => wf.isActive && wf.assessment === cAssessment?.id),
    [workflows, cAssessment?.id],
  );
  // const workflow = useMemo(() => workflows && workflows.length > 0 ? workflows[0] : undefined, [workflows]);
  const currSurvey = useMemo(() => surveys?.find((sur) => sur.id === workflow.bpSurvey), [surveys, workflow.bpSurvey]);
  const currAssSurvey = useMemo(
    () => (cAssessment?.surveys as AssessmentSurvey[])?.find((assSur) => assSur?.id === workflow?.assessmentSurvey),
    [cAssessment?.surveys, workflow.assessmentSurvey],
  );
  // simple/short functions
  const handleAcceptAssessmentOpen = useCallback(() => setAcceptAssessmentOpen(true), []);
  const handleAcceptAssessmentClose = useCallback(() => setAcceptAssessmentOpen(false), []);
  const handleRejectAssessmentOpen = useCallback(() => setRejectAssessmentOpen(true), []);
  const handleRejectAssessmentClose = useCallback(() => setRejectAssessmentOpen(false), []);
  const handleDeleteAssessmentOpen = useCallback(() => setDeleteAssessmentOpen(true), []);
  const handleDeleteAssessmentClose = useCallback(() => setDeleteAssessmentOpen(false), []);

  const handleAssessmentDeleteSubmit = useCallback(
    async (assessmentId: number) => {
      dispatch(deleteAssessment(assessmentId));
      if (workflow) dispatch(updateWorkflow({ adminOverride: true, id: workflow.id, assessment: null, assessmentSurvey: null, assessor: null, assignedAssessor: null, status: 'submittedForAssessment' }));
      handleDeleteAssessmentClose();
    },
    [dispatch],
  );

  const handleAssessmentUpdate = useCallback(
    async (status: string) => {
      if (status === 'Pre-Assessment') {
        const newAssSurveys = await updateMultipleAssessmentSurveys(
          (cAssessment?.surveys as AssessmentSurvey[]).map((csur) => {
            return { ...csur, status: 'Pre-Assessment' };
          }),
        );
        dispatch(
          updateAssessment({
            ...cAssessment,
            status: 'Assessing',
            dateAccepted: moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
          }),
        );

        if (activeWorkflows) {
          activeWorkflows.forEach((wf) => dispatch(updateWorkflow({ id: wf.id, status: 'Pre-Assessment' })));
        }

        setcAssessment({ ...cAssessment, status: 'Pre-Assessment', surveys: newAssSurveys } as Assessment);
      } else {
        dispatch(updateAssessment({ ...cAssessment, status }));
        if (activeWorkflows) {
          activeWorkflows.forEach((wf) => dispatch(updateWorkflow({ id: wf.id, status: status, adminOverride: true })));
        }
        setcAssessment({ ...cAssessment, status: status } as Assessment);
      }

      handleAcceptAssessmentClose();
      handleRejectAssessmentClose();

      if (status === 'Rejected') {
        assessmentRejectedAlert(
          typeof cAssessment?.company === 'number' ? cAssessment?.company : cAssessment?.company.id!,
          typeof cAssessment?.assessor === 'number' ? cAssessment?.assessor : cAssessment?.assessor.id!,
        );
      }
    },
    [cAssessment, activeWorkflows, dispatch],
  );

  const updateAssessmentFromAssessmentSurvey = useCallback(
    (assessmentSurvey: AssessmentSurvey) => {
      const assessmentSurveyIndex = (cAssessment?.surveys as AssessmentSurvey[]).findIndex(
        (assSur) => assSur.id === assessmentSurvey.id,
      );
      if (assessmentSurveyIndex === -1) return;
      const newAssessment = JSON.parse(JSON.stringify(cAssessment));
      newAssessment.surveys[assessmentSurveyIndex] = assessmentSurvey;
      setcAssessment(newAssessment);
    },
    [cAssessment],
  );

  const surveyRow = useMemo(
    () => (assessmentSurvey: AssessmentSurvey) => {
      if (!assessmentSurvey || (company.type === COMPANY_TYPES.ASSESSOR && !assessedCompany) || !surveys) return;
      // if (!assessmentSurvey || (company.type === COMPANY_TYPES.ASSESSOR && !assessedCompany) || !workflow?.bpSurvey) return;
      const theCompany = assessedCompany ? assessedCompany : company;

      const survey = surveys.find((sur) =>
        typeof assessmentSurvey.survey === 'number'
          ? sur.id === assessmentSurvey.survey
          : sur.id === assessmentSurvey.survey.id!,
      );
      if (!survey) return;

      const location = survey.site ? theCompany.locations.find((loc) => loc.id === survey.site) : undefined;
      const app = survey.application
        ? theCompany.companyApplications?.find((napp) => napp.application.id === survey.application)
        : undefined;

      return (
        <AssessmentSurveyRow
          key={assessmentSurvey.id}
          companyApplication={app}
          site={location}
          company={theCompany}
          assessment={cAssessment!}
          survey={survey}
          assessmentSurvey={assessmentSurvey}
          updateAssessmentFromAssessmentSurvey={updateAssessmentFromAssessmentSurvey}
        />
      );
    },
    [company, assessedCompany, surveys],
  );

  // Assessor Comment Modal Logic
  const [showAssessmentComments, setShowAssessmentComments] = useState(false);
  const [assessmentComment, setAssessmentComment] = useState<string>('');
  useEffect(() => {
    if (currAssSurvey?.assessorComment) {
      setAssessmentComment(currAssSurvey.assessorComment);
    }
  }, [currAssSurvey?.assessorComment]);

  const handleSetShowAssessmentComments = useCallback((comment: string) => {
    setAssessmentComment(comment);
    setShowAssessmentComments(true);
  }, [setAssessmentComment, setShowAssessmentComments]);

  const handleAssessmentComment = useCallback(async (assessorComment: string) => {
    if (currAssSurvey !== undefined) {
      try {
        await updateAssessmentSurvey({ ...currAssSurvey, assessorComment });
        setAssessmentComment(assessorComment);
      } catch (e) {
        console.error('failed to update assessmentSurvey');
      }
    }
  }, [currAssSurvey]);

  // fetching assessment
  useEffect(() => {
    if (workflow?.assessment) {
      fetchAssessment(workflow.assessment!).then((newAssessment) => setcAssessment(newAssessment));
    }
  }, [workflow.assessment]);

  // updating assessment
  useEffect(() => {
    const filteredSurveys = (cAssessment?.surveys as AssessmentSurvey[])?.map((sur) => sur.survey as Survey);
    setFilteredSurveys(filteredSurveys ? filteredSurveys.filter(sur => sur) : []);
    if (['Assigned', 'Rejected'].indexOf(cAssessment?.status!) === -1 && !cAssessment?.dateAccepted) {
      dispatch(updateAssessment({ ...cAssessment, dateAccepted: cAssessment?.createdAt }));
    }
  }, [cAssessment]);

  // setting of vendor company
  useEffect(() => {
    setAssessedCompany(vendorCompany);
  }, [vendorCompany]);

  // show alert
  useEffect(() => {
    if (!cAssessment?.surveys.length) return;
    if (!assessmentAuditLog.length) {
      setShowAlert(false);
      return;
    }
    const curAssessmentSurveys = (cAssessment?.surveys as AssessmentSurvey[]).filter(
      (assSur) => ['Complete', 'Remediation'].indexOf(assSur.status) === -1,
    );
    const assessmetnSurveyIds = curAssessmentSurveys.map((assSur) => assSur.id);
    setShowAlert(
      assessmentAuditLog.find(
        (assAl) =>
          assessmetnSurveyIds.indexOf(assAl.assessmentSurvey) > -1 &&
          assAl.auditLogs.filter((al) => (al.user as User).id !== user?.id).length,
      ) !== undefined,
    );
  }, [assessmentAuditLog, cAssessment]);

  const [siteCloudStatus, setSiteCloudStatus] = useState<SiteCloudStatus>({ site: false, cloud: false, both: false });

  const fetchSiteCloudStatus = async (baselineId: number) => {
    const siteCloundData = await getSiteCloudStatus(baselineId);
    setSiteCloudStatus(siteCloundData);
  };

  useEffect(() => {
    if (workflow?.baseline) fetchSiteCloudStatus(workflow.baseline);
  }, [workflow?.baseline]);

  if (!cAssessment) {
    return null;
  }
  const commonTypographyStyles = {
    marginBottom: '15px',
    color: theme.palette.customThemeColors?.darkGrey,
    fontSize: '14px',
  };

  return (
    <>
    <AssessorAssessmentComments
      canEdit={user && user.type === COMPANY_TYPES.ASSESSOR && currAssSurvey && ['Complete', 'Remediation'].indexOf(currAssSurvey.status) === -1}
      originalComment={assessmentComment}
      handleAssessmentComment={handleAssessmentComment}
      setShowAssessmentComments={setShowAssessmentComments}
      showAssessmentComments={showAssessmentComments}
    />
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary>
        <DataRow>
          <DataCell xs={4}>
            {showAlert && (
              <NotificationImportantRounded
                color='warning'
                sx={{ position: 'absolute', top: '0', left: '0', width: '.8em' }}
              />
            )}
            {company.type === COMPANY_TYPES.VENDOR
              ? (cAssessment?.assessor as Company)?.name
              : (cAssessment?.company as Company)?.name}
          </DataCell>
          <DataCell xs={3}>{cAssessment?.surveys.length}</DataCell>
          <DataCell xs={2}>
            <WorkflowStatus
              company={company}
              workflow={workflow}
              survey={currSurvey}
              assessmentSurvey={currAssSurvey}
            />
          </DataCell>
          <DataCell xs={2}>
            {company.type === COMPANY_TYPES.VENDOR && userCanAdminCompany && cAssessment?.status === 'Rejected' && (
              <Button
                variant='outlined'
                sx={{ width: '150px' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAssessmentModalOpen(true);
                }}
              >
                Reassign
              </Button>
            )}
            {['Assigned', 'Rejected'].indexOf(cAssessment?.status!) === -1 && (
              <Button sx={{ maxWidth: '100%', width: '205px', mt: 1 }} size='small' variant='outlined' onClick={(e) => {e.stopPropagation(); handleSetShowAssessmentComments(assessmentComment ?? currAssSurvey?.assessorComment);}}>Assessor Summary</Button>
            )}
            {company.type === COMPANY_TYPES.ASSESSOR && (
              <>
                {cAssessment?.status === 'Assigned' && (
                  <Stack spacing={1} alignItems='center'>
                    <Button
                      variant='outlined'
                      sx={{ width: '150px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAcceptAssessmentOpen();
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant='outlined'
                      color='error'
                      sx={{ width: '150px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRejectAssessmentOpen();
                      }}
                    >
                      Reject
                    </Button>
                  </Stack>
                )}
              </>
            )}
            {company.type !== COMPANY_TYPES.ASSESSOR &&
              !(company.type === COMPANY_TYPES.VENDOR && userCanAdminCompany && cAssessment?.status === 'Rejected') && <></>}
          </DataCell>
          <DataCell xs={1}>
            {(userCanAdminCompany &&
              user?.type === USER_TYPES.VENDOR_ADMIN &&
              ['Assigned', 'Rejected'].indexOf(cAssessment?.status!) !== -1) ||
            user?.type === USER_TYPES.TPN_ADMIN ? (
              <DataRowActions>
                <IconButton
                  size='small'
                  name='Delete Assessment'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDeleteAssessmentOpen();
                  }}
                >
                  <Delete />
                </IconButton>
                {['Assigned', 'Rejected'].indexOf(cAssessment?.status!) !== -1 && (
                  <IconButton
                    size='small'
                    name='Edit Assessment'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAssessmentModalOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </DataRowActions>
              ) : (
              <Typography align='center'>-</Typography>
              )}
          </DataCell>
        </DataRow>
      </AccordionSummary>
      <DataRow header>
        <DataCell xs={2}>Type</DataCell>
        <DataCell xs={2}>Name</DataCell>
        <DataCell xs={2}>Scope</DataCell>
        <DataCell xs={2}>Type</DataCell>
        <DataCell xs={2}>Status</DataCell>
        <DataCell xs={2} textAlign='center'>
          Actions
        </DataCell>
      </DataRow>
      {(cAssessment?.surveys as AssessmentSurvey[]).map((survey) => surveyRow(survey))}
      <StandardDialog
        title={'Delete Assessment?'}
        handleClose={handleDeleteAssessmentClose}
        isOpen={deleteAssessmentOpen}
      >
        <Typography>Are you sure you want to delete this assessment? Please notify the assessor after deleting.</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleDeleteAssessmentClose()}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Delete />}
            onClick={() => handleAssessmentDeleteSubmit(cAssessment?.id as number)}
            disabled={!userCanAdminCompany}
          >
            Delete
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog
        title={'Accept Assessment?'}
        handleClose={handleAcceptAssessmentClose}
        isOpen={acceptAssessmentOpen}
      >
        <Typography sx={{ ...commonTypographyStyles, fontSize: '16px' }}>
          Are you sure you want to accept this assessment?
        </Typography>
        <Typography sx={commonTypographyStyles}>
          By clicking &quot;ACCEPT&quot; you confirm that you have verified the Baseline Questionnaire answers and the Assessment Scope.
        </Typography>
        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <FormGroup sx={{ marginLeft: '7px' }}>
            <FormLabel component="legend">Scope</FormLabel>
            <FormControlLabel
              labelPlacement="end"
              disabled
              control={<Switch checked={siteCloudStatus.site || siteCloudStatus.both} />}
              label={
                <Typography sx={{ ...commonTypographyStyles, marginBottom: 0 }}>
                  {surveys[0]?.site ? 'Site' : 'On-Prem'}
                </Typography>
              }
            />
            <FormControlLabel
              labelPlacement="end"
              disabled
              control={<Switch checked={siteCloudStatus.cloud || siteCloudStatus.both} />}
              label={
                <Typography sx={{ ...commonTypographyStyles,  marginBottom: 0 }}>
                  Cloud
                </Typography>
              }
            />
          </FormGroup>
        </Stack>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleAcceptAssessmentClose()}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              handleAssessmentUpdate('Pre-Assessment');
              handleAcceptAssessmentClose();
            }}
          >
            Accept
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog
        title={'Reject Assessment?'}
        handleClose={handleRejectAssessmentClose}
        isOpen={rejectAssessmentOpen}
      >
        <Typography>Are you sure you want to reject this assessment?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleRejectAssessmentClose()}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              handleAssessmentUpdate('Rejected');
              handleRejectAssessmentClose();
            }}
            disabled={!userCanAdminCompany}
          >
            Reject
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      {((userCanAdminCompany &&
        user?.type === USER_TYPES.VENDOR_ADMIN &&
        ['Assigned', 'Rejected'].indexOf(cAssessment?.status!) !== -1) ||
        user?.type === USER_TYPES.TPN_ADMIN) && (
        <AssessmentForm
          assessment={cAssessment}
          modalOpen={assessmentModalOpen}
          company={company}
          closeCallback={() => setAssessmentModalOpen(false)}
        />
      )}
    </Accordion>
    </>
  );
};

export default AssessmentDataRow;
