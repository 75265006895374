import React, { useEffect, useState, useMemo } from 'react';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';

import moment from 'moment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import { Survey } from '../../interfaces/survey.interface';
import { fetchSurvey } from '../../services/surveys';
import { buildFullQuestionnaireObject } from '../../services/questionnaires';
import { fetchQuestionDependantObjects, initialBuildOutQuestionnaire } from '../../services/buildQuestionnaire';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { Question } from '../../interfaces/question.interface';
import { getCompany } from '../../services/company';
import { Badge, Company, CompanyApplication, Contact } from '../../interfaces/users.interface';
import { QuestionAnswer } from '../../interfaces/questionAnswer.interface';
import { appTheme } from '../Themes/light';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Answer } from '../../interfaces/answer.interface';
import { useAppSelector } from '../../app/hooks';
import { Topic } from '../../interfaces/topic.interface';
import { WORKFLOW_MAP, fetchWorkflows } from '../../services/workflow';
import { Workflow } from '../../interfaces/workflow.interface';
import  {
  getManyEvidence,
} from '../../services/questionAnswer';
import { FULLY_IMPLEMENTED, NOT_APPLICABLE, NOT_IMPLEMENTED } from '../../services/surveyHelpers';

export default function SurveyReportMenuItemOption(props: { surveyId?: number, workflow: Workflow }) {
  const { surveyId, workflow } = props;
  const { user } = useAppSelector(state => state.user);
  const [reportLoading, setReportLoading] = useState(false);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [baseline, setBaseline] = useState<Survey | undefined>(undefined);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | undefined>(undefined);
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [domainRollUps, setDomainRollUp] = useState<any>(undefined);
  const [bpDisplay, setBPDisplay] = useState<any>(undefined);
  const [reportReady, setReportReady] = useState(false);
  const [site, setSite] = useState<Contact | undefined>(undefined);
  const [application, setApplication] = useState<CompanyApplication | undefined>(undefined);
  const [employeeCount, setEmployeeCount] = useState<Answer | undefined>(undefined);
  const [PDFReport, setPDFReport] = useState<pdfMake.TCreatedPdf | undefined>(undefined);
  const [PDFReportTitle, setPDFReportTitle] = useState<string | undefined>(undefined);

  const isWorkflow53OrGreater = useMemo(() => !['v5.1', 'v5.2'].includes(WORKFLOW_MAP[workflow.workflowType]?.version), [workflow]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const badge = (workflow?.badges as Badge[])?.find(b => b.title === 'tpn_self_reported');

  const buildReport = async () => {
    if (!survey || !survey.questionnaire) {
      setReportLoading(false);
      return;
    }

    setReportLoading(true);
    const surQuestionnaire = survey.questionnaire as Questionnaire;

    getCompany(typeof survey.company === 'number' ? survey.company.toString() : survey.company.id!.toString()).then(comp => setCompany(comp));
    const questionnaireObjects = await buildFullQuestionnaireObject(surQuestionnaire.id);
    const builtQuestionnaire = initialBuildOutQuestionnaire({ ...questionnaireObjects, questionnaire: surQuestionnaire });
    setQuestionnaire(builtQuestionnaire.questionnaire);

    const fetchQuestions = await fetchQuestionDependantObjects(false, builtQuestionnaire.allQuestions);
    setQuestions(fetchQuestions.questions);
  };

  const loopQuestionnaire = async () => {
    if (!survey || !company || !questionnaire || !questions || questions.length === 0) {
      setReportLoading(false);
      return;
    }

    const questionAnswers = survey.questionAnswers;

    if (!questionAnswers || questionAnswers.length === 0) {
      setReportLoading(false);
      return;
    }

    const rollups: any[] = [];
    const bps: any[] = [];

    for (const domain of questionnaire.domains) {
      const domainRollUp: any = { name: domain.title, bpCounts: { fi: 0, pi: 0, ni: 0, na: 0 }, igCounts: { fi: 0, pi: 0, ni: 0, na: 0 } };
      for (const topic of domain.topics) {
        for (const bp of topic.bestPractices) {
          const curBP = { bp, domain, topic, questions: ([] as any[]) };
          for (const questionId of (bp.questions as unknown as number[])) {
            const question = questions.find(q => q.id === questionId);
            const answer = (survey.questionAnswers as QuestionAnswer[]).find(qa => qa.question === questionId);
            const qAnswer = question?.answers.find(ans => ans.id === answer?.answer?.[0]);
            let revealQuestion = !question?.inclusions.length || question.inclusions.some(({ answer: inclusionAnswer, question: inclusionQuestion }) => {
              const inclusionAnswerId = (inclusionAnswer as Answer).id;
              const inclusionQuestionId = (inclusionQuestion as Question).id;
            
              const surveyQuestionAnswer = (survey.questionAnswers as QuestionAnswer[]).find(qa => qa.question === inclusionQuestionId);
              const baselineQuestionAnswer = (baseline?.questionAnswers as QuestionAnswer[])?.find(qa => qa.question === inclusionQuestionId);
            
              return (
                baselineQuestionAnswer?.answer?.includes(inclusionAnswerId) ||
                surveyQuestionAnswer?.answer?.includes(inclusionAnswerId) ||
                surveyQuestionAnswer?.compensatingControlAnswers?.includes(inclusionAnswerId)
              );
            });

            if (!question || !revealQuestion) continue;

            // Fetch attachments if they exist
            let attachments: any[] = [];
            if (answer?.attachments && answer.attachments.length > 0) {
              try {
                attachments = await getManyEvidence(answer.attachments);
                answer.attachments = attachments;
              } catch (error) {
                console.error(`Error fetching evidence for question ${questionId}:`, error);
              }
            }

            curBP.questions.push({ question, answer, qAnswer });
          }
          if (curBP.questions.length > 0) bps.push(curBP);
        }
      }
      rollups.push(domainRollUp);
    }
    setDomainRollUp(rollups);
    setBPDisplay(bps);
    setReportLoading(false);
    setReportReady(true);
  };

  const setSiteOrApplication = () => {
    if (!company || !survey) return;

    const csite = survey.site ? company.locations.find(location => location.id === survey.site) : undefined;
    const capplication = survey.application ? company.companyApplications?.find(companyApplication => companyApplication.application.id === survey.application) : undefined;

    setSite(csite);
    setApplication(capplication);
  };

  useEffect(() => {
    if (!survey) return;
    buildReport();
  }, [survey]);

  useEffect(() => {
    if (!questions || !company || !questionnaire) return;
    loopQuestionnaire();
    setSiteOrApplication();
  }, [questions, company, questionnaire]);

  const fetchBaselineQuestions = async () => {
    if ((!site && !application) || !company || !baseline) return;

    const getBaselineQuestions = await fetchQuestionDependantObjects(true, (baseline.questionAnswers as QuestionAnswer[]).map(qa => qa.question));
    const baselineQuestions = getBaselineQuestions.questions;
    if (!baselineQuestions.length || !baseline.questionAnswers?.length) return;
    const employeeCountQuestion = baselineQuestions.find(question => question.title.toLowerCase().indexOf('number') !== -1 && question.title.toLowerCase().indexOf('employees') !== -1);
    if (employeeCountQuestion) {
      const questionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === employeeCountQuestion.id);
      if (questionAnswer && questionAnswer.answer?.length) setEmployeeCount(employeeCountQuestion.answers.find(answer => answer.id === questionAnswer.answer![0]));
    }
    if (bpDisplay) {
      setReportLoading(false);
      setReportReady(true);
    }
  };

  useEffect(() => {
    if ((!site && !application) || !company || !baseline) return;
    fetchBaselineQuestions();
  }, [baseline, site, application, company]);

  const requestBuildReport = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (reportLoading) return;
    if (!surveyId) {
      setReportLoading(false);
      return;
    }

    setReportLoading(true);
    fetchSurvey(surveyId).then(sur => setSurvey(sur));
    fetchWorkflows({ 'bp_survey': surveyId }).then(wf => {
      if (wf.length > 0) {
        fetchSurvey(wf[0].baseline!).then(bas => setBaseline(bas));
      }
    });
  };

  const outputOverviewBox = () => {
    if (!survey || !company) return;
    const output = {
      margin: [0, 0, 0, 20],
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
          [{
            border: [true, false, true, true],
            stack: [
              { text: [{ text: 'Survey Scope:', bold: true }, ` ${survey?.site ? 'Site' : application?.sites.length ? 'Site' : 'On-Prem'}`] },
              { text: [{ text: 'Survey Type:', bold: true }, ` ${survey?.site ? 'On Site' : 'Remote'}`] },
            ],
          }],
        ],
      },
    };

    if (site) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facility:', bold: true }, ` ${site.name}`] });
    if ((application && application.sites?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facilities:', bold: true }, ` ${application.sites.map(csite => csite.name).join(', ')}`] });
    if ((site && site.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${site.services.map(service => service.name).join(', ')}`] });
    if ((application && application.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${application.services.map(service => service.name).join(', ')}`] });
    if (employeeCount?.text) output.table.body[0][0].stack.push({ text: [{ text: 'Number of Employees:', bold: true }, ` ${employeeCount.text}`] });
    const ownedApps = company?.companyApplications
      ?.filter(
        (ca) =>
          ca.application.company &&
          (typeof ca.application.company === 'number' && ca.application.company === company.id) ||
          (typeof ca.application.company !== 'number' && ca.application.company?.id === company.id),
      );
    if (site && ownedApps?.length) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Applications:', bold: true }, ` ${ownedApps.map(app => app.application.name).join(', ')}`] });
    if (application) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Application:', bold: true }, application.application.name ] });
    const thirdPartyApps = company?.companyApplications
      ?.filter(
        (ca) =>
          !ca.application.company ||
          (typeof ca.application.company === 'number' && ca.application.company !== company.id) ||
          (typeof ca.application.company !== 'number' && ca.application.company.id !== company.id),
      );
    if (site && thirdPartyApps?.length) output.table.body[0][0].stack.push({ text: [{ text: '3rd Party Licensed Applications:', bold: true }, ` ${thirdPartyApps.map(app => app.application.name).join(', ')}`] });

    return output;
  };

  const outputBPItems = () => {
    const bpItems:any = bpDisplay.map((bpitem: any, index: number) => {
      const item:any = [
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.code}: ${bpitem.domain.title} - ${bpitem.topic.title}`,
              } ],
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.topics.findIndex((top: Topic) => bpitem.topic.id === top.id) + 1}.${bpitem.topic.bestPractices.findIndex((bp: BestPractice) => bpitem.bp.id === bp.id)} ${bpitem.bp.title}`,
              }],
            ],
          },
        },
      ];
      bpitem.questions.map((qContain: any) => {
        const fullyImplementedAnswers = qContain.question.answers.filter((answer: Answer) => ![NOT_APPLICABLE, NOT_IMPLEMENTED].includes(answer.text || ''));
        const isFullyImplemented = qContain.answer?.answer.length === fullyImplementedAnswers.length;
        const hasNoAnswer = !qContain.answer?.answer?.length
          && !qContain.answer?.compensatingControlAnswers?.length
          && !qContain.answer?.nonApplicableAnswers?.length
          &&  !qContain.answer?.compensatingControlAnswers?.length;

        const notImplementedAnswer = qContain.question.answers.find((a: Answer) => a.text === NOT_IMPLEMENTED);
        const notApplicableAnswer = qContain.question.answers.find((a: Answer) => a.text === NOT_APPLICABLE);
        const answerIsNotImplemented = qContain.answer?.answer.includes(notImplementedAnswer?.id);

        const getFileName = (url: string): string => {
          try {
            return new URL(url).pathname.split('/').pop()!;
          } catch {
            return url.split('?')[0].split('/').pop()!;
          }
        };
        const attachmentText = qContain.answer?.attachments
          ?.map((attachment: any) => {
            const fileName = getFileName(attachment.file.document);
            return `${fileName} (${attachment.isPublic ? 'Public' : 'Private'})`;
          })
          .join(', ');

        const questionArray: any = [
          qContain.question.isBestPractice
            ? {
              columns: [
                {
                  table: {
                    widths: [16],
                    body: [
                      [
                        {
                          image: 'WorkspacePremium',
                          width: 16,
                          margin: [0, 1, 5, 0],
                          valign: 'top',
                        },
                      ],
                    ],
                  },
                  layout: 'noBorders',
                  width: 21,
                },
                {
                  fontSize: 16,
                  text: qContain.question.title,
                  margin: [0, 5, 0, 5],
                  bold: true,
                },
              ],
            }
            : {
              fontSize: 16,
              text: qContain.question.title,
              margin: [0, 5, 0, 5],
              bold: true,
            },
          {
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: isFullyImplemented
                      ? FULLY_IMPLEMENTED
                      : answerIsNotImplemented
                        ? NOT_IMPLEMENTED
                        : qContain.answer?.answer.includes(notApplicableAnswer?.id)
                          ? NOT_APPLICABLE
                          : (hasNoAnswer || qContain?.question?.type === 'single_select')
                            ? ''
                            : 'Partially Implemented',
                    bold: true,
                    fillColor: isFullyImplemented
                      ? appTheme.palette.success.main
                      : answerIsNotImplemented
                        ? appTheme.palette.error.main
                        : qContain?.answer?.answer.includes(notApplicableAnswer?.id)
                          ? '#ccc'
                          : appTheme.palette.nonCompliant.main,
                    color: isFullyImplemented || answerIsNotImplemented || hasNoAnswer ? 'white' : 'black',
                    margin: [10, 5, 10, 5],
                  },
                ],
              ],
            },
            layout: 'noBorders',
            margin: [0, 0, 5, 10],
          },
          {
            table: {
              widths: ['*', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [
                  { text: '' },
                  { text: 'YES', alignment: 'center', noWrap: true },
                  { text: 'C/C', alignment: 'center', noWrap: true },
                  { text: 'N/I', alignment: 'center', noWrap: true },
                  { text: 'N/A', alignment: 'center', noWrap: true },
                ],
                ...qContain.question.answers
                  .filter((a: Answer) => isWorkflow53OrGreater ? ![qContain?.question?.type === 'single_select' ? '' : NOT_APPLICABLE, NOT_IMPLEMENTED, FULLY_IMPLEMENTED].includes(a.text || '') : true)
                  .map((answer: Answer) => {
                    const answered = qContain.answer && qContain.answer.answer.findIndex((ans: number) => ans === answer.id) > -1;
                    const answeredCC = qContain.answer && qContain.answer.compensatingControlAnswers.findIndex((ans: number) => ans === answer.id) > -1;
                    const answeredNI = qContain.answer && qContain.answer.nonImplementedAnswers.findIndex((ans: number) => ans === answer.id) > -1;
                    const answeredNA = qContain.answer && qContain.answer.nonApplicableAnswers.findIndex((ans: number) => ans === answer.id) > -1;

                    const onlyYesAnswer =
                      answer.text === FULLY_IMPLEMENTED ||
                      answer.text === NOT_IMPLEMENTED ||
                      answer.text === NOT_APPLICABLE;

                    return [
                      { text: answer.text },
                      { text: answered ? 'X' : '', alignment: 'center' },
                      {
                        text: onlyYesAnswer ? '-' : (answeredCC ? 'X' : ''),
                        alignment: 'center',
                        color: onlyYesAnswer ? 'grey' : 'black',
                        fillColor: onlyYesAnswer ? '#d3d3d3' : undefined,
                      },
                      {
                        text: onlyYesAnswer ? '-' : (answeredNI ? 'X' : ''),
                        alignment: 'center',
                        color: onlyYesAnswer ? 'grey' : 'black',
                        fillColor: onlyYesAnswer ? '#d3d3d3' : undefined,
                      },
                      {
                        text: onlyYesAnswer ? '-' : (answeredNA ? 'X' : ''),
                        alignment: 'center',
                        color: onlyYesAnswer ? 'grey' : 'black',
                        fillColor: onlyYesAnswer ? '#d3d3d3' : undefined,
                      },
                    ];
                  }),
              ],
            },
            margin: [0, 0, 10, 10],
          },
          qContain.answer?.comment
            ? {
              fontSize: 12,
              text: [
                { text: 'Additional Details: ', bold: true },
                { text: qContain.answer.comment },
              ],
              margin: [0, 0, 0, 5],
            }
            : {},
          qContain.answer?.attachments?.length > 0
            ? {
              fontSize: 12,
              lineHeight: 1.2,
              text: [
                { text: 'Attachments: ', bold: true },
                { text: attachmentText },
              ],
              margin: [0, 0, 0, 0],
            }
            : {},
        ];

        item.push({
          table: {
            headerRows: 0,
            widths: ['*'],
            body: [
              [{
                stack: questionArray,
              }],
            ],
          },
          layout: 'noBorders',
          margin: [0, 10, 0, 0],
        });
      });
      if (index + 1 !== bpDisplay.length) return { stack: item, margin: [0, 0, 0, 10] };
      return { stack: item };
    });

    return bpItems;
  };

  const generatePDF = async () => {
    if (!(reportReady && bpDisplay && domainRollUps && company && questionnaire && user)) return;

    const curDate = moment();

    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
      MaterialSymbolsOutlined: {
        normal: 'https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2',
      },
    };

    const docDefinition: any = {
      pageOrientation: 'landscape',
      content: [
        {
          stack: [
            { image: 'TPN', fit: [400, 400], margin: [0, 0, 0, 10] },
            { text: 'BLUE SELF-REPORTED QUESTIONNAIRE', fontSize: 25 },
            ...(site ?
              [
                {
                  stack: [
                    company.name,
                    site.name,
                    site.address,
                    site.address2,
                    site.address3,
                    `${site.city}, ${site.state} ${site.zipcode}`,
                    site.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${site.primaryContact?.firstName} ${site.primaryContact?.lastName}`,
                site.primaryContact?.email,
                site.primaryContact?.contact?.phoneNumber,
              ]
              :
              [
                {
                  stack: [
                    company.name,
                    company.contact?.address,
                    company.contact?.address2,
                    company.contact?.address3,
                    `${company.contact?.city}, ${company.contact?.state} ${company.contact?.zipcode}`,
                    company.contact?.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${company.primaryContactUser?.firstName} ${company.primaryContactUser?.lastName}`,
                company.primaryContactUser?.email,
                company.primaryContactUser?.contact?.phoneNumber,
              ]
            ),
            {
              stack: [
                `Report Generated: ${moment().format('MMM DD, YYYY')}`,
                `Questionnaire Completed: ${moment(badge?.createdAt).format('MMM DD, YYYY')}`,
                `Expiration Date: ${moment(badge?.expirationDate).format('MMM DD, YYYY')}`,
              ], fontSize: 12, margin: [0, 20, 0, 0],
            },
            { text: questionnaire.title, margin: [0, 10, 0, 0] },
          ], alignment: 'center', pageBreak: 'after',
        },
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Overview',
              }],
            ],
          },
        },
        outputOverviewBox(),
        {
          stack: outputBPItems(),
        },
      ],
      images: {
        TPN: `${window.location.origin}/assets/TPN_BlueLogoTransparent_1280wide.png`,
        WorkspacePremium: `${window.location.origin}/assets/Workspace-Premium.png`,
      },
      styles: {
        headerBG: {
          fontSize: 16,
          color: 'white',
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
      info: {
        title: `TPN-Best_Practice_Questionnaire-${encodeURIComponent(company.name.replace(/\s+/g, '-'))}-${curDate.format('YYYY-MM-DD')}`,
        author: 'Trusted Partner Network',
      },
      watermark: { text: `TPN Best Practice Questionnaire - ${user.email} - ${curDate.format('MMMM DD YYYY')}`, color: 'blue', opacity: 0.2, bold: true, italics: false },
      footer: function (currentPage: number, pageCount: number) { return { text: currentPage.toString() + ' of ' + pageCount, alignment: 'center', margin: [0, 10, 0, 0 ] }; },
      header: { text: `TPN Best Practice Questionnaire: ${company.name} ${curDate.format('MMMM DD YYYY')}`, alignment: 'center', margin: [0, 5, 0, 5] },
    };

    // Set the PDFReport and PDFReportTitle for subsequent downloads.
    const pdfDoc: pdfMake.TCreatedPdf = pdfMake.createPdf(docDefinition);
    const pdfDocTitle = `TPN-Best_Practice_Questionnaire-${encodeURIComponent(company.name.replace(/\s+/g, '-'))}-${curDate.format('YYYY-MM-DD')}.pdf`;
    setPDFReport(pdfDoc);
    setPDFReportTitle(pdfDocTitle);
    pdfDoc.download(pdfDocTitle);
  };

  useEffect(() => {
    if (reportReady && bpDisplay && domainRollUps && company && questionnaire && user) generatePDF();
  }, [reportReady, bpDisplay, domainRollUps, company, questionnaire, user]);

  return (
    <>
      {!surveyId ? (
        <MenuItem>
          <ListItemText primary="Could not find questionnaire" />
        </MenuItem>
      ) : (
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (PDFReport && PDFReportTitle) {
            PDFReport.download(PDFReportTitle);
          } else {
            requestBuildReport(e);
          }
        }}>
          <ListItemIcon>
            {reportLoading ?
              <CircularProgress size='20px' /> :
              (<img
                src={`${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`}
                alt="Blue Badge"
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  border: '1px solid #ddd',
                  backgroundColor: '#fff',
                }}
              />)}
          </ListItemIcon>
          <ListItemText primary='Questionnaire Report' />
        </MenuItem>
      )}
    </>
  );
}
